import Axios from '@/axios'
import domain from '@/environment'
import PermissionSets from '../../models/crm/PermissionSets'
import Permission from '../../models/crm/Permission'
import User from '../../models/crm/User'
const state = {
  permissionerror: null,
  permissionLoading: false,
  usersLoading: false,
  permissionUpdateLoading: false,
  updateUsersLoading: false,
  permission: [],
  permissionset: [],
  listUsers: []
}
const getters = {
  getPermissionLoading: state => state.permissionLoading,
  getPermissionUpdateLoading: state => state.permissionUpdateLoading,
  getUsersLoading: state => state.usersLoading,
  getUpdateUsersLoading: state => state.updateUsersLoading,
  getPermissionError: state => state.permissionerror,
  getListePermission: state => state.permission,
  getListePermissionSet: state => state.permissionset,
  getListeUsers: state => state.listUsers
}
const mutations = {
  PERMISSION_LOADING(state, payload) {
    state.permissionLoading = payload
  },
  USERS_LOADING(state, payload) {
    state.usersLoading = payload
  },
  PERMISSION_UPDATE_LOADING(state, payload) {
    state.permissionUpdateLoading = payload
  },
  UPDATE_USERS_LOADING(state, payload) {
    state.updateUsersLoading = payload
  },
  PERMISSION_ERROR(state, payload = null) {
    state.permissionerror = payload
  },
  SET_LISTE_PERMISSION(state, payload) {
    state.permission = payload.map(item => Permission.create(item))
  },
  SET_LISTE_PERMISSION_SET(state, payload) {
    state.permissionset = payload.map(item => PermissionSets.create(item))
  },
  SET_LISTE_USERS(state, payload) {
    state.listUsers = payload.map(user => User.create(user))
  },
  SET_LISTE_USERS_AVEC_PERMISSION_SETS(state, { permissionSet, data }) {
    permissionSet.setUsers(data)
  },
  SET_ADD_PERMISSION_SETS(state, payload) {
    state.permissionset.push(PermissionSets.create(payload.response))
  },
  SET_LISTE_PERMISSION_PERMISSION_SET(state, { permissionSet, data }) {
    permissionSet.setPermission(data)
  },
  SET_UPDATE_PERMISSION_SET_PERMISSION(
    state,
    { ensemble, permissionToAdd, add }
  ) {
    if (add) {
      ensemble.permissions.push(permissionToAdd)
    } else {
      ensemble.permissions = ensemble.permissions.filter(
        p => p.id != permissionToAdd.id
      )
    }
  },
  SET_UPDATE_USERS_PERMISSION_SET(state, { ensemble, users }) {
    ensemble.users = users
  },
  SET_UPDATE_NAME_PERMISSION_SET(state, payload) {
    payload.ensemble.updatePermissionSets(payload.data)
  },
  REMOVE_PERMISSION_SET(state, payload) {
    state.permissionset = state.permissionset.filter(ensemble => {
      return ensemble.id != payload.id
    })
  },
  SET_DELETE_USERS_PERMISSION_SET(state, { ensemble, usersToDelete }) {
    ensemble.users = ensemble.users.filter(p => p.id != usersToDelete.id)
  }
}
const actions = {
  async addPermisssionSet({ commit }, payload) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    const body = {
      name: payload.name,
      users: payload.users,
      permissions: payload.permission ? payload.permission : []
    }
    try {
      const response = await Axios.post(domain + '/api/permissionsSets', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ADD_PERMISSION_SETS', {
        permission: payload,
        response: response.data.data
      })
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatePermissionPermissionSet(
    { commit },
    { ensemble, permissionToAdd, add }
  ) {
    commit('PERMISSION_UPDATE_LOADING', true)
    commit('PERMISSION_ERROR')
    let data = { permissions: [] }
    data.permissions = ensemble.permissions.map(p => p.id)

    if (add) {
      data.permissions.push(permissionToAdd.id)
    } else {
      data.permissions.splice(data.permissions.indexOf(permissionToAdd.id), 1)
    }

    try {
      await Axios.put(domain + '/api/permissionsSets/' + ensemble.id, data, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_UPDATE_PERMISSION_SET_PERMISSION', {
        ensemble,
        permissionToAdd,
        add
      })
      commit('PERMISSION_UPDATE_LOADING')
    } catch (error) {
      commit('PERMISSION_UPDATE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateUserPermissionSet({ commit }, { ensemble, usersToAdd }) {
    commit('UPDATE_USERS_LOADING', true)
    commit('PERMISSION_ERROR')
    const users = [...ensemble.users, ...usersToAdd]
    try {
      await Axios.put(
        domain + '/api/permissionsSets/' + ensemble.id,
        { users: users.map(u => u.id) },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_UPDATE_USERS_PERMISSION_SET', {
        ensemble,
        users
      })
      commit('UPDATE_USERS_LOADING')
    } catch (error) {
      commit('UPDATE_USERS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteUserPermissionSet({ commit }, { ensemble, usersToDelete }) {
    const users = [...ensemble.users.filter(p => p.id != usersToDelete.id)]
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      await Axios.put(
        domain + '/api/permissionsSets/' + ensemble.id,
        { users: users.map(p => p.id) },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_DELETE_USERS_PERMISSION_SET', {
        ensemble,
        usersToDelete
      })
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateNamePermissionSet({ commit }, { ensemble, data }) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      await Axios.put(domain + '/api/permissionsSets/' + ensemble.id, data, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_UPDATE_NAME_PERMISSION_SET', {
        ensemble,
        data
      })
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletePermissionSet({ commit }, payload) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      await Axios.delete(domain + '/api/permissionsSets/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PERMISSION_SET', payload)
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListePermission({ commit }) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      const response = await Axios.get(domain + '/api/permissions', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response.data) {
        commit('SET_LISTE_PERMISSION', response.data.data)
      }
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListePermissionSet({ commit }) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      const response = await Axios.get(domain + '/api/permissionsSets', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response.data) {
        commit('SET_LISTE_PERMISSION_SET', response.data.data)
      }
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListeUsersPermission({ commit }) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/users?exclude=team,technicianPipe,visibleTeams,commercialItePipe,userPipe,depot',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_LISTE_USERS', response.data.data)
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListeUsersAvecPermissionSets({ commit }, { permissionSet }) {
    commit('USERS_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/permissionsSets/' +
          permissionSet.id +
          '/users?exclude=team,technicianPipe,visibleTeams,commercialItePipe,userPipe,depot',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response.data) {
        commit('SET_LISTE_USERS_AVEC_PERMISSION_SETS', {
          permissionSet,
          data: response.data.data
        })
      }
      commit('USERS_LOADING')
    } catch (error) {
      commit('USERS_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListePermissionSets({ commit }, { permissionSet }) {
    commit('PERMISSION_LOADING', true)
    commit('PERMISSION_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/permissionsSets/' + permissionSet.id + '/permissions',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response.data) {
        commit('SET_LISTE_PERMISSION_PERMISSION_SET', {
          permissionSet,
          data: response.data.data
        })
      }
      commit('PERMISSION_LOADING')
    } catch (error) {
      commit('PERMISSION_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('PERMISSION_ERROR', error.response.data.error.messages)
      } else {
        commit('PERMISSION_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorPermission({ commit }) {
    commit('PERMISSION_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
