import Entity from '../Entity'
import ProjectType from './ProjectType'
import User from './User'
import * as moment from 'moment'
moment.locale('fr')
export default class HistoryExportExcel extends Entity {
  constructor(data) {
    super(data.id)
    if (data.dtype && data.dtype.data) {
      this.dtype = ProjectType.create(data.dtype.data)
    }
    this.filter = data.filter
    this.lien = data.lien
    this.saved_filter = data.saved_filter
    this.timestamp = data.timestamp
      ? moment(data.timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
      : data.timestamp
    if (data.user && data.user.data) {
      this.user = User.create(data.user.data)
    }
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('HistoryExportExcel::create: "data" is undefined')
    }
    return new HistoryExportExcel(data)
  }
  update(data) {
    if (data.dtype && data.dtype.data) {
      this.dtype = ProjectType.create(data.dtype.data)
    }
    this.filter = data.filter
    this.lien = data.lien
    this.saved_filter = data.saved_filter
    this.timestamp = data.timestamp
      ? moment(data.timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
      : data.timestamp
    if (data.user && data.user.data) {
      this.user = User.create(data.user.data)
    }
  }
}
