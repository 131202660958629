import Entity from '../Entity'

export default class Precarite extends Entity {
  constructor(data) {
    super(data.id)
    this.nbr_personne = data.nbr_personne
    this.revenus_idf = data.revenus_idf ? parseFloat(data.revenus_idf) : 0
    this.revenus_province = data.revenus_province
      ? parseFloat(data.revenus_province)
      : 0
    this.type = data.type
  }
  /**
   *
   * @param { id, name, projet_count} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Precarite::create: "data" is undefined')
    }
    return new Precarite(data)
  }
  update(data) {
    this.nbr_personne = data.nbr_personne
    this.revenus_idf = data.revenus_idf ? parseFloat(data.revenus_idf) : 0
    this.revenus_province = data.revenus_province
      ? parseFloat(data.revenus_province)
      : 0
    this.type = data.type
  }
}
