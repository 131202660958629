import Entity from '../Entity'

export default class Webhook extends Entity {
  constructor(data) {
    super(data.id)
    this.action = data.action
    this.object = data.object
    this.url = data.url
    this.created_at = data.created_at
    this.created_by = data.created_by
    this.last_delivery_time = data.last_delivery_time
    this.last_http_status = data.last_http_status
  }
  /**
   *
   * @param { id, name, projet_count} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Webhook::create: "data" is undefined')
    }
    return new Webhook(data)
  }
}
