import Entity from '../Entity'

export default class Stage extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.color = data.color
    this.status = data.status
    this.csubstages = data.csubstages
  }
  static create(data) {
    if (!data) {
      throw new Error('Stage::create: "data" is undefined')
    }
    return new Stage(data)
  }
}
