import Entity from '../Entity'
import User from './User'

export default class History extends Entity {
  constructor(data) {
    super(data.id)
    this.contact = data.contact
    this.current = data.current
    this.date = data.date
    this.entity = data.entity
    this.entityId = data.entityId
    this.historique_id = data.historique_id
    this.data_historique_id = data.data_historique_id
    this.key = data.key
    this.name = data.name
    this.previous = data.previous
    this.projet = data.projet
    this.type = data.type

    if (data.user) {
      if (data.user.data) {
        this.user = User.create(data.user.data)
      } else {
        this.user = User.create(data.user)
      }
    }
    this.from_api_pixel_externe = data.from_api_pixel_externe
    this.object = 'ChangeProjet'
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('History::create: "data" is undefined')
    }
    return new History(data)
  }
}
