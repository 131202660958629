import Axios from '@/axios'
import domain from '@/environment'
import User from '../../models/crm/User'
import VisibilityRegie from '../../models/crm/VisibilityRegie'

const state = {
  visibiliteRegieError: null,
  visibiliteRegieLoading: false,
  visibiliteRegieUsersLoading: false,
  visibiliteRegie: [],
  visibilityListUsers: []
}
const getters = {
  getvisibiliteRegieError: state => state.visibiliteRegieError,
  getvisibiliteRegieLoading: state => state.visibiliteRegieLoading,
  getvisibiliteRegieUsersLoading: state => state.visibiliteRegieUsersLoading,
  getvisibiliteRegie: state => state.visibiliteRegie,
  getVisibilityListeUsers: state => state.visibilityListUsers
}
const mutations = {
  SET_VISIBILITY_REGIE_LOADING(state, payload = false) {
    state.visibiliteRegieLoading = payload
  },
  SET_VISIBILITY_REGIE_USERS_LOADING(state, payload = false) {
    state.visibiliteRegieUsersLoading = payload
  },
  SET_VISIBILITY_REGIE_ERROR(state, payload = null) {
    state.visibiliteRegieError = payload
  },
  SET_LISTE_TEAMS_REGIE(state, payload) {
    state.visibiliteRegie = payload.map(item => VisibilityRegie.create(item))
  },
  SET_LISTE_USERS_VOIR_EQUIPE(state, payload) {
    state.visibilityListUsers = payload.map(user => User.create(user))
  },
  SET_LISTE_USERS_EQUIPE(state, { regie, data }) {
    regie.setVisibilityUsers(data)
  },
  ADD_USERS_VISIBLE_TEAMS(state, { regie, usersToAdd }) {
    regie.users = [...regie.users, usersToAdd]
  },
  SET_DELETE_USER(state, { regie, usersToDelete }) {
    regie.users = regie.users.filter(p => p.id != usersToDelete.id)
  }
}
const actions = {
  async fetchListeTeamsRegie({ commit }, payload) {
    commit('SET_VISIBILITY_REGIE_LOADING', true)
    commit('SET_VISIBILITY_REGIE_ERROR')
    const params = {}
    if (payload && payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    try {
      const response = await Axios.get(domain + '/api/teams-regie', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      if (response.data) {
        commit('SET_LISTE_TEAMS_REGIE', response.data.data)
      }
      commit('SET_VISIBILITY_REGIE_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_REGIE_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_VISIBILITY_REGIE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_VISIBILITY_REGIE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListeUsersVoirEquipe({ commit }, payload) {
    commit('SET_VISIBILITY_REGIE_USERS_LOADING', true)
    commit('SET_VISIBILITY_REGIE_ERROR')
    const params = {}
    if (payload && payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    try {
      const response = await Axios.get(
        domain + '/api/possible-team/' + payload.id + '/supervisors',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      if (response.data) {
        commit('SET_LISTE_USERS_VOIR_EQUIPE', response.data.data)
      }
      commit('SET_VISIBILITY_REGIE_USERS_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_REGIE_USERS_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_VISIBILITY_REGIE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_VISIBILITY_REGIE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListeUsersEquipe({ commit }, payload) {
    commit('SET_VISIBILITY_REGIE_LOADING', true)
    commit('SET_VISIBILITY_REGIE_ERROR')
    const params = {}
    if (payload && payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    try {
      const response = await Axios.get(
        domain + '/api/teams/' + payload.id + '/visibleTo',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_LISTE_USERS_EQUIPE', {
        regie: payload.regie,
        data: response.data.data
      })
      commit('SET_VISIBILITY_REGIE_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_REGIE_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_VISIBILITY_REGIE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_VISIBILITY_REGIE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async makeTeamVisibleUsers({ commit }, payload) {
    commit('SET_VISIBILITY_REGIE_LOADING', true)
    commit('SET_VISIBILITY_REGIE_ERROR')
    const params = {}
    if (payload && payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    try {
      const response = await Axios.post(
        domain + '/api/make-team/' + payload.regie.id + '/visible-to-users',
        { users: [payload.usersToAdd.id] },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('ADD_USERS_VISIBLE_TEAMS', {
        regie: payload.regie,
        usersToAdd: payload.usersToAdd,
        data: response.data.data
      })
      commit('SET_VISIBILITY_REGIE_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_REGIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_VISIBILITY_REGIE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_VISIBILITY_REGIE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async makeTeamInvisibleUsers({ commit }, payload) {
    const params = {}
    if (payload && payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_REGIE_LOADING', true)
    commit('SET_VISIBILITY_REGIE_ERROR')
    try {
      await Axios.post(
        domain + '/api/make-team/' + payload.regie.id + '/invisible-to-users',
        { users: [payload.usersToDelete.id] },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_DELETE_USER', {
        regie: payload.regie,
        usersToDelete: payload.usersToDelete
      })
      commit('SET_VISIBILITY_REGIE_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_REGIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_VISIBILITY_REGIE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_VISIBILITY_REGIE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorVisibilityRegie({ commit }) {
    commit('SET_VISIBILITY_REGIE_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
