import Entity from '../Entity'

export default class VisibilityRegie extends Entity {
  constructor(data) {
    super(data.id)
    this.coeff_101 = data.coeff_101
    this.coeff_102 = data.coeff_102
    this.coeff_103 = data.coeff_103
    this.created_by = data.created_by
    this.depots = data.depots
    this.image = data.image
    this.image_name = data.image_name
    this.manager = data.manager
    this.members = data.members
    this.name = data.name
    this.owner_id = data.owner_id
    this.proprietaire = data.proprietaire
    this.regie = data.regie
    this.see_form_fast = data.see_form_fast
    this.source_id = data.source_id
    this.type = data.type
    this.visibleToUsers = data.visibleToUsers
    this.users = null
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('VisibilityRegie::create: "data" is undefined')
    }
    return new VisibilityRegie(data)
  }
  setVisibilityUsers(users) {
    this.users = users
  }
}
