import { uuid } from 'vue-uuid'

export default class Entity {
  /**
   * Super constructor to all entities.
   * @param {*string} id
   */
  constructor(id) {
    if (this.constructor === Entity) {
      throw new TypeError(
        'Abstruct class "Entity" cannot be instantiated directly'
      )
    }

    if (id && id != null) {
      this.id = id
    } else {
      this.id = uuid.v1()
    }
  }

  /**
   * Check if current entity is equals to an other entity.
   * @param {*Entity} e
   */
  equalsTo(e) {
    return e && this.id == e.id
  }

  static create() {
    throw new Error('You have to implement the method "create"')
  }
}
