import Entity from '../Entity'

export default class Deposits extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.meta.depot_name
    this.filiale = data.meta.filiale_name
    this.days = data.days
    this.totals = data.total_depot
  }

  static create(data) {
    if (!data) {
      throw new Error('Deposits::create: "data" is undefined')
    }
    return new Deposits(data)
  }
}
