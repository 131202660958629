import Entity from '../Entity'
import User from './User'
import * as moment from 'moment'
moment.locale('fr')
export default class HistoryPlanningPoseur extends Entity {
  constructor(data) {
    super(data.id)
    this.previous = data.previous
    this.current = data.current
    if (data.key) {
      switch (data.key) {
        case 'row_index_1_on':
          this.key = 'Ligne 1'
          break
        case 'row_index_2_on':
          this.key = 'Ligne 2'
          break
        case 'row_index_3_on':
          this.key = 'Ligne 3'
          break
        case 'row_index_4_on':
          this.key = 'Ligne 4'
          break
        case 'row_index_5_on':
          this.key = 'Ligne 5'
          break
        case 'optimised':
          this.key = 'Tableaux'
          break
      }
    }

    this.day = data.day
    this.timestamp = data.timestamp
    if (data.user && data.user.data) {
      this.user = User.create(data.user.data)
    }
    if (data.technician && data.technician.data) {
      this.technician = User.create(data.technician.data)
    }
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('HistoryPlanningPoseur::create: "data" is undefined')
    }
    return new HistoryPlanningPoseur(data)
  }
}
