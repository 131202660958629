import Entity from '../Entity'
export default class CustomField extends Entity {
  constructor(data) {
    super(data.id)
    this.key = data.key
    this.name = data.name
    this.order = data.order
    this.subcategory_header = data.subcategory_header
  }

  /**
   * @param { id,key, name, order, subcategory_header} data
   */
  static create(data) {
    if (!data) {
      throw new Error('CustomField::create: "data" is undefined')
    }
    return new CustomField(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
    this.subcategory_header = data.subcategory_header
  }
}
