import Entity from '../Entity'

export default class User extends Entity {
  constructor(data) {
    super(data.id)
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.full_name = data.full_name
    this.email = data.email
    this.deposit = data.depot
    this.team = data.team
    this.type = data.type
    if (!this.full_name) {
      this.full_name = `${data.first_name} ${data.last_name}`
    }
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('User::create: "data" is undefined')
    }
    return new User(data)
  }
}
