import Axios from '@/axios'
import domain from '@/environment'

const state = {
  tokenError: null,
  tokenLoading: false,
  token: null
}
const getters = {
  getApiTokenError: state => state.tokenError,
  getApiTokenLoading: state => state.tokenLoading,
  getApiToken: state => state.token
}
const mutations = {
  SET_API_LOADING(state, payload = false) {
    state.tokenLoading = payload
  },
  SET_API_ERROR(state, payload = null) {
    state.tokenError = payload
  },
  SET_API_TOKEN(state, payload) {
    state.token = payload
  }
}
const actions = {
  async generateApiToken({ commit }) {
    commit('SET_API_LOADING', true)
    commit('SET_API_ERROR')

    try {
      const response = await Axios.post(
        domain + '/api/crm/generateToken',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_API_TOKEN', response.data.data.token_key)
      commit('SET_API_LOADING')
    } catch (error) {
      commit('SET_API_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_API_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_API_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchApiToken({ commit }) {
    commit('SET_API_LOADING', true)
    commit('SET_API_ERROR')

    try {
      const response = await Axios.get(domain + '/api/crm/Token', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_API_TOKEN', response.data.data.token_key)
      commit('SET_API_LOADING')
    } catch (error) {
      commit('SET_API_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_API_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_API_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
