import Entity from '../Entity'

export default class PermissionSets extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.permissions = null
    this.users = null
  }

  static create(data) {
    if (!data) {
      throw new Error('PermissionSets::create: "data" is undefined')
    }
    return new PermissionSets(data)
  }
  updatePermissionSets(data) {
    this.name = data.name
  }
  setPermission(permissions) {
    this.permissions = permissions
  }
  setUsers(users) {
    this.users = users
  }
}
