import Entity from '../Entity'

export default class Permission extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.identifiant = data.identifiant
  }

  static create(data) {
    if (!data) {
      throw new Error('Permission::create: "data" is undefined')
    }
    return new Permission(data)
  }
}
