import Axios from '@/axios'
import AffectationLeads from '../../models/crm/AffectationLeads'
import domain from '@/environment'
let request = null
const state = {
  leadsError: null,
  leadsLoading: false,
  departementLoading: false,
  listUsers: [],
  departement: [],
  usersCountDepartement: 1
}
const getters = {
  getLeadsError: state => state.leadsError,
  getLeadsLoading: state => state.leadsLoading,
  getDepartementLoading: state => state.departementLoading,
  getlistUsers: state => state.listUsers,
  getListDepartement: state => state.departement,
  usersCountDepartement: state => state.usersCountDepartement
}
const mutations = {
  SET_LEADS_LOADING(state, payload = false) {
    state.leadsLoading = payload
  },
  SET_DEPARTEMENT_LOADING(state, payload = false) {
    state.departementLoading = payload
  },
  SET_LEADS_ERROR(state, payload = null) {
    state.leadsError = payload
  },
  SET_LIST_USERS(state, payload) {
    state.listUsers = payload.data.map(item => AffectationLeads.create(item))
    state.usersCountDepartement = payload.meta.pagination.total
  },
  SET_LIST_DEPARTEMENT(state, payload) {
    state.departement = payload
  },
  UPDATE_DEPARTEMENT(state, payload) {
    payload.users.update(payload.response)
  }
}
const actions = {
  async fetchListUsers({ commit }, payload) {
    commit('SET_LEADS_LOADING', true)
    commit('SET_LEADS_ERROR')
    const params = {
      page: payload.page,
      per_page: payload.per_page
    }
    if (payload.full_name) {
      params.full_name = payload.full_name
    }
    try {
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/all-comm_sed', {
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LIST_USERS', response.data)
      commit('SET_LEADS_LOADING')
    } catch (error) {
      commit('SET_LEADS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_LEADS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_LEADS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListDepartement({ commit }) {
    commit('SET_DEPARTEMENT_LOADING', true)
    commit('SET_LEADS_ERROR')

    try {
      const response = await Axios.get(domain + '/api/all-deps', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_LIST_DEPARTEMENT', response.data)
      commit('SET_DEPARTEMENT_LOADING')
    } catch (error) {
      commit('SET_DEPARTEMENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_LEADS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_LEADS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateDepartement({ commit }, payload) {
    commit('SET_LEADS_LOADING', true)
    commit('SET_LEADS_ERROR')
    const body = {
      department_ids: payload.department_ids
    }
    try {
      const response = await Axios.put(
        domain + '/api/user-departments/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_DEPARTEMENT', {
        users: payload,
        response: response.data
      })
      commit('SET_LEADS_LOADING')
    } catch (error) {
      commit('SET_LEADS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_LEADS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_LEADS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
