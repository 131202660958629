import Entity from '../Entity'

export default class VisibilityProject extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.type = data.type
    let or = []
    let and = []
    for (let index = 0; index < 2; index++) {
      if (data.conditions.conditions[index]) {
        if (data.conditions.conditions[index].glue == 'and') {
          and = data.conditions.conditions[index].conditions
        }
        if (data.conditions.conditions[index].glue == 'or') {
          or = data.conditions.conditions[index].conditions
        }
      }
    }
    this.conditions = { and, or }
    this.users = []
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('VisibilityProject::create: "data" is undefined')
    }
    return new VisibilityProject(data)
  }
}
