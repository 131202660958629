import Stage from './Stage'
import Entity from '../Entity'
export default class Pipeline extends Entity {
  #stages
  constructor(data) {
    super(data.id)
    this.name = data.name
    if (data.cstages && data.cstages.data && Array.isArray(data.cstages.data)) {
      this.#stages = data.cstages.data.map(dataStage => Stage.create(dataStage))
    }
  }
  static create(data) {
    if (!data) {
      throw new Error('Pipeline::create: "data" is undefined')
    }
    return new Pipeline(data)
  }

  get stages() {
    return this.#stages
  }
}
