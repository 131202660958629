import Axios from '@/axios'
import domain from '@/environment'
import User from '../../models/crm/User'
import VisibilityProject from '../../models/crm/VisibilityProject'
const state = {
  visibilityProjectError: null,
  visibilityProjectLoading: false,
  projectUsersLoading: false,
  projectDeleteUsersLoading: false,
  projectChangeUsersLoading: false,
  projectListUsersLoading: false,
  visibilityProjectOperatorsLoading: false,
  fields: [],
  operators: [],
  value: [],
  users: [],
  filters: []
}
const getters = {
  getVisibilityProjectError: state => state.visibilityProjectError,
  gettVisibilityProjectLoading: state => state.visibilityProjectLoading,
  getProjectUsersLoading: state => state.projectUsersLoading,
  getProjectDeleteUsersLoading: state => state.projectDeleteUsersLoading,
  getProjectChangeUsersLoading: state => state.projectChangeUsersLoading,
  getProjectListUsersLoading: state => state.projectListUsersLoading,
  gettVisibilityProjectOperators: state =>
    state.visibilityProjectOperatorsLoading,
  getFieldsToFiltre: state => state.fields,
  getOperatorToFiltre: state => state.operators,
  getValueToFields: state => state.value,
  getUsers: state => state.users,
  getFiltersProject: state => state.filters
}
const mutations = {
  SET_VISIBILITY_PROJECT_LOADING(state, payload = false) {
    state.visibilityProjectLoading = payload
  },
  SET_VISIBILITY_PROJECT_OPERATORS_LOADING(state, payload = false) {
    state.visibilityProjectOperatorsLoading = payload
  },
  SET_VISIBILITY_PROJECT_USER_LOADING(state, payload = false) {
    state.projectUsersLoading = payload
  },
  SET_VISIBILITY_PROJECT_DELETE_USER_LOADING(state, payload = false) {
    state.projectDeleteUsersLoading = payload
  },
  SET_VISIBILITY_PROJECT_CHANGE_USER_LOADING(state, payload = false) {
    state.projectChangeUsersLoading = payload
  },
  SET_VISIBILITY_PROJECT_LIST_USER_LOADING(state, payload = false) {
    state.projectListUsersLoading = payload
  },
  SET_VISIBILITY_PROJECT_ERROR(state, payload = null) {
    state.visibilityProjectError = payload
  },
  SET_FIELDS(state, fields) {
    state.fields = fields.data
  },
  SET_OPERATORS(state, payload) {
    const operators = []
    const keys = Object.keys(payload.response.data)
    keys.forEach(key => {
      operators.push({
        key: key,
        label: payload.response.data[key]
      })
    })
    state.operators[payload.field_key] = {
      operators,
      links: payload.response.links
    }
  },
  SET_VALUE(state, payload) {
    state.value = payload.response.data
  },
  SET_LISTE_USERS(state, payload) {
    state.users = payload.map(user => User.create(user))
  },
  SET_LISTE_FILTERS(state, payload) {
    state.filters = payload.map(filter => VisibilityProject.create(filter))
  },
  ADD_FILTER(state, payload) {
    state.filters.push(VisibilityProject.create(payload))
  },
  UPDATE_FILTER(state, payload) {
    state.filters = state.filters.map(item =>
      item.id == payload.response.id
        ? VisibilityProject.create(payload.response)
        : item
    )
  },
  REMOVE_FILTER(state, payload) {
    state.filters = state.filters.filter(filter => {
      return filter.id != payload.id
    })
  },
  SET_LISTE_FILTER_USERS(state, payload) {
    payload.addUserfilter.users.push(payload.response.id)
    const indice = payload.filter.users.findIndex(user => {
      return user.id == payload.response.id
    })
    payload.filter.users.splice(indice, 1)
  },
  REMOVE_FILTER_USERS(state, { filter, users }) {
    filter.users = filter.users.filter(user => user.id != users.id)
  },
  ADD_USERS_TO_FILTER(state, { filter, users }) {
    filter.users = users
  },
  SET_USERS_IN_FILTER(state, { filter, response }) {
    filter.listUsers(response)
  }
}
const actions = {
  async fetchFieldsToFilter({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    const params = {}
    if (payload.for_visibility) {
      params.for_visibility = payload.for_visibility
    }
    try {
      const response = await Axios.post(
        domain + '/api/fields-filter',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_FIELDS', response.data)
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOperatorsToFields({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/fields-operators',
        { field_key: payload.field_key },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_OPERATORS', {
        response: response.data,
        field_key: payload.field_key
      })
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchvalueOfFields({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_OPERATORS_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.get(domain + payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_VALUE', {
        response: response.data
      })
      commit('SET_VISIBILITY_PROJECT_OPERATORS_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_OPERATORS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListeUsers({ commit }, payload) {
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_PROJECT_LIST_USER_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.get(domain + '/api/users-no-vfilters', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LISTE_USERS', response.data.data)
      commit('SET_VISIBILITY_PROJECT_LIST_USER_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LIST_USER_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListFiltres({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude
    }
    if (payload.for_visibility) {
      params.for_visibility = payload.for_visibility
    }
    try {
      const response = await Axios.get(domain + '/api/pfilters', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LISTE_FILTERS', response.data)
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addFilter({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.post(domain + '/api/pfilters', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_FILTER', response.data)
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteFilter({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      await Axios.delete(domain + '/api/pfilters/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_FILTER', payload)
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateFiltre({ commit }, payload) {
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    const body = {
      conditions: payload.conditions,
      name: payload.name,
      type: payload.type
    }
    try {
      const response = await Axios.put(
        domain + '/api/pfilters/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_FILTER', {
        filter: payload,
        response: response.data
      })
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addFilterUsers({ commit }, payload) {
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_PROJECT_CHANGE_USER_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/ass-users/' +
          payload.users.id +
          '/vfilters/' +
          payload.addUserfilter.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_LISTE_FILTER_USERS', {
        addUserfilter: payload.addUserfilter,
        users: payload.users,
        filter: payload.filter,
        response: response.data.data
      })
      commit('SET_VISIBILITY_PROJECT_CHANGE_USER_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_CHANGE_USER_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteFilterUsers({ commit }, payload) {
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_PROJECT_DELETE_USER_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/diss-users/' +
          payload.users.id +
          '/vfilters/' +
          payload.filter.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('REMOVE_FILTER_USERS', {
        filter: payload.filter,
        users: payload.users,
        response: response.data.data
      })
      commit('SET_VISIBILITY_PROJECT_DELETE_USER_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_DELETE_USER_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addUsersToFilters({ commit }, payload) {
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_PROJECT_USER_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    const users = [...payload.filter.users, ...payload.users]
    try {
      const response = await Axios.post(
        domain + '/api/pfilters/' + payload.filter.id + '/users',
        { users: users.map(u => u.id) },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('ADD_USERS_TO_FILTER', {
        filter: payload.filter,
        users,
        response: response.data.data
      })
      commit('SET_VISIBILITY_PROJECT_USER_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_USER_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersInFilter({ commit }, payload) {
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude.join()
    }
    commit('SET_VISIBILITY_PROJECT_LOADING', true)
    commit('SET_VISIBILITY_PROJECT_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/pfilters/' + payload.filter.id + '/users',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_USERS_IN_FILTER', {
        filter: payload.filter,
        response: response.data.data
      })
      commit('SET_VISIBILITY_PROJECT_LOADING')
    } catch (error) {
      commit('SET_VISIBILITY_PROJECT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_VISIBILITY_PROJECT_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_VISIBILITY_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorFilter({ commit }) {
    commit('SET_VISIBILITY_PROJECT_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
