import Entity from '../../Entity'
export default class Categorie extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.icon_name = data.icon_name
    this.order = data.order
    this.subCategorie = []
    if (data.dtypes && data.dtypes.data) {
      this.dtypes = data.dtypes.data
    }
    if (data.subCategorie) {
      this.subCategorie = data.subCategorie
    }
  }

  /**
   * @param { id, name, subCategorie } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Categorie::create: "data" is undefined')
    }
    return new Categorie(data)
  }

  update(data) {
    this.name = data.name
    this.icon_name = data.icon_name
    this.order = data.order
    if (data.dtypes && data.dtypes.data) {
      this.dtypes = data.dtypes.data
    }
  }
}
