import Entity from '../Entity'

export default class AffectationLeads extends Entity {
  constructor(data) {
    super(data.id)
    this.first_name = data.first_name
    this.type = data.type
    this.full_name = data.full_name
    this.departments = data.departments
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('AffectationLeads::create: "data" is undefined')
    }
    return new AffectationLeads(data)
  }
  update(data) {
    this.first_name = data.first_name
    this.type = data.type
    this.full_name = data.full_name
    this.departments = data.departments
  }
}
