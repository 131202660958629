import Axios from '@/axios'
import Objectifs from '../../models/reporting/Objectifs'
import Deposits from '../../models/reporting/Deposits'
import { uuid } from 'vue-uuid'
import domain from '@/environment'

let request = null

const state = {
  stats: null,
  error: null,
  errorStatistic: null,
  processing: false,
  processingStatistic: false,
  objectifs: [],
  statFiliales: [],
  deposits: null,
  reportingTerrain: [],
  reportingTerrainProcessing: false,
  reportingTerrainError: null
}
const getters = {
  getReportingStats: state => state.stats,
  getReportingError: state => state.error,
  getReportingProcessing: state => state.processing,
  getReportingErrorStatic: state => state.errorStatistic,
  getReportingProcessingStatic: state => state.processingStatistic,
  getReportingObjectifs: state => state.objectifs,
  getReportingDeposits: state => state.deposits,
  getReportingStatFiliales: state => state.statFiliales,
  getReportingTerrain: state => state.reportingTerrain,
  getReportingTerrainProcessing: state => state.reportingTerrainProcessing,
  getReportingTerrainError: state => state.reportingTerrainError
}
const mutations = {
  SET_REPORTING_STATS(state, payload) {
    state.stats = payload
  },
  SET_REPORTING_ERROR(state, payload) {
    state.error = payload
    state.processing = false
  },
  REMOVE_REPORTING_ERROR(state) {
    state.error = null
  },
  SET_REPORTING_PROCESSING(state, payload) {
    state.processing = payload
    state.error = null
  },
  SET_REPORTING_ERROR_STATIC(state, payload) {
    state.errorStatistic = payload
    state.processingStatistic = false
  },
  REMOVE_REPORTING_ERROR_STATIC(state) {
    state.errorStatistic = null
  },
  SET_REPORTING_PROCESSING_STATIC(state, payload) {
    state.processingStatistic = payload
    state.errorStatistic = null
  },
  INIT_REPORTING_OBJECTIFS(state) {
    state.objectifs = Objectifs.create({})
  },
  SET_REPORTING_OBJECTIFS(state, payload) {
    state.objectifs.deposits.push(Deposits.create(payload))
  },
  SET_REPORTING_OBJECTIFS_TOTAL(state) {
    const filiales = {}
    state.objectifs.deposits.forEach(d => {
      for (let index = 0; index < d.days.length; index++) {
        const day = d.days[index]
        state.objectifs.days[index].m_objectif += day.totals_day.m_objectif
        state.objectifs.days[index].pose_objectif +=
          day.totals_day.pose_objectif
        state.objectifs.days[index].m_planife += day.totals_day.m_planife
        state.objectifs.days[index].pose_planifie +=
          day.totals_day.pose_planifie
        state.objectifs.days[index].m_realise += day.totals_day.m_realise
        state.objectifs.days[index].pose_realise += day.totals_day.pose_realise
      }
      state.objectifs.totals.m_objectif += d.totals.m_objectif_w
      state.objectifs.totals.pose_objectif += d.totals.pose_objectif_w
      state.objectifs.totals.m_planife += d.totals.m_planife_w
      state.objectifs.totals.pose_planifie += d.totals.pose_planifie_w
      state.objectifs.totals.m_realise += d.totals.m_realise_w
      state.objectifs.totals.pose_realise += d.totals.pose_realise_w
      state.objectifs.chartDataDeposit.push([d.name, d.totals.pose_realise_w])
      if (!filiales[d.filiale]) {
        filiales[d.filiale] = 0
      }
      filiales[d.filiale] += d.totals.pose_realise_w
    })
    for (const [key, value] of Object.entries(filiales)) {
      state.objectifs.chartDataFiliale.push([key, value])
    }
    state.objectifs.loaded = true
  },
  SET_REPORTING_DEPOSITS(state, payload) {
    state.deposits = payload
  },
  SET_REPORTING_STATISTICS(state, payload) {
    let newFiliale = true
    for (let index = 0; index < state.statFiliales.length; index++) {
      if (
        state.statFiliales[index] &&
        state.statFiliales[index].name == payload.meta.filiale_name
      ) {
        state.statFiliales[index].deposits = [
          Deposits.create(payload),
          ...state.statFiliales[index].deposits
        ]
        const x = state.statFiliales[index].deposits.length - 1
        const t = state.statFiliales[index].deposits[x]
        t.totals.ca_value_w += payload.total_depot.ca_value_w
        t.totals.camion_active_w += payload.total_depot.camion_active_w
        t.totals.camion_ca_w += payload.total_depot.camion_ca_w
        t.totals.camion_m_w += payload.total_depot.camion_m_w
        t.totals.cls_value_w += payload.total_depot.cls_value_w
        t.totals.m_planife_w += payload.total_depot.m_planife_w
        t.totals.m_realise_w += payload.total_depot.m_realise_w
        t.totals.p_value_w += payload.total_depot.p_value_w
        t.totals.planif_pose_percent_w +=
          payload.total_depot.planif_pose_percent_w
        t.totals.pose_planifie_w += payload.total_depot.pose_planifie_w
        t.totals.pose_realise_w += payload.total_depot.pose_realise_w
        t.totals.t_101_w += payload.total_depot.t_101_w
        t.totals.t_102_w += payload.total_depot.t_102_w
        t.totals.t_103_w += payload.total_depot.t_103_w
        t.totals.wa_lead_ca_w += payload.total_depot.wa_lead_ca_w
        t.totals.wa_lead_taux_w += payload.total_depot.wa_lead_taux_w
        t.totals.wa_regie_ca_w += payload.total_depot.wa_regie_ca_w
        t.totals.wa_regie_taux_w += payload.total_depot.wa_regie_taux_w
        t.totals.wa_terrain_ca_w += payload.total_depot.wa_terrain_ca_w
        t.totals.wa_terrain_taux_w += payload.total_depot.wa_terrain_taux_w

        newFiliale = false
      }
    }
    if (newFiliale) {
      const total = {}
      total.totals = {
        ca_value_w: payload.total_depot.ca_value_w,
        camion_active_w: payload.total_depot.camion_active_w,
        camion_ca_w: payload.total_depot.camion_ca_w,
        camion_m_w: payload.total_depot.camion_m_w,
        cls_value_w: payload.total_depot.cls_value_w,
        m_planife_w: payload.total_depot.m_planife_w,
        m_realise_w: payload.total_depot.m_realise_w,
        p_value_w: payload.total_depot.p_value_w,
        planif_pose_percent_w: payload.total_depot.planif_pose_percent_w,
        pose_planifie_w: payload.total_depot.pose_planifie_w,
        pose_realise_w: payload.total_depot.pose_realise_w,
        t_101_w: payload.total_depot.t_101_w,
        t_102_w: payload.total_depot.t_102_w,
        t_103_w: payload.total_depot.t_103_w,
        wa_lead_ca_w: payload.total_depot.wa_lead_ca_w,
        wa_lead_taux_w: payload.total_depot.wa_lead_taux_w,
        wa_regie_ca_w: payload.total_depot.wa_regie_ca_w,
        wa_regie_taux_w: payload.total_depot.wa_regie_taux_w,
        wa_terrain_ca_w: payload.total_depot.wa_terrain_ca_w,
        wa_terrain_taux_w: payload.total_depot.wa_terrain_taux_w
      }
      total.name = 'Total'
      total.class = 'total'
      const filiale = {
        name: payload.meta.filiale_name,
        deposits: [Deposits.create(payload), total]
      }

      state.statFiliales.push(filiale)
    }
  },
  RESET_REPORTING_STATIC(state) {
    state.statFiliales = []
  },
  SET_REPORTING_STATISTICS_TOTAL(state) {
    const GeneralEnergie = {
      ca_value_w: 0,
      camion_ca_w: 0,
      camion_m_w: 0,
      camion_active_w: 0,
      cls_value_w: 0,
      m_planife_w: 0,
      m_realise_w: 0,
      p_value_w: 0,
      planif_pose_percent_w: 0,
      pose_planifie_w: 0,
      pose_realise_w: 0,
      t_101_w: 0,
      t_102_w: 0,
      t_103_w: 0,
      wa_lead_ca_w: 0,
      wa_lead_taux_w: 0,
      wa_regie_ca_w: 0,
      wa_regie_taux_w: 0,
      wa_terrain_ca_w: 0,
      wa_terrain_taux_w: 0
    }
    state.statFiliales.forEach(d => {
      const total = d.deposits[d.deposits.length - 1]
      GeneralEnergie.ca_value_w += total.totals.ca_value_w
      GeneralEnergie.camion_ca_w += total.totals.camion_ca_w
      GeneralEnergie.camion_m_w += total.totals.camion_m_w
      GeneralEnergie.camion_active_w += total.totals.camion_active_w
      GeneralEnergie.cls_value_w += total.totals.cls_value_w
      GeneralEnergie.m_planife_w += total.totals.m_planife_w
      GeneralEnergie.m_realise_w += total.totals.m_realise_w
      GeneralEnergie.p_value_w += total.totals.p_value_w
      GeneralEnergie.planif_pose_percent_w += total.totals.planif_pose_percent_w
      GeneralEnergie.pose_planifie_w += total.totals.pose_planifie_w
      GeneralEnergie.pose_realise_w += total.totals.pose_realise_w
      GeneralEnergie.t_101_w += total.totals.t_101_w
      GeneralEnergie.t_102_w += total.totals.t_102_w
      GeneralEnergie.t_103_w += total.totals.t_103_w
      GeneralEnergie.wa_lead_ca_w += total.totals.wa_lead_ca_w
      GeneralEnergie.wa_lead_taux_w += total.totals.wa_lead_taux_w
      GeneralEnergie.wa_regie_ca_w += total.totals.wa_regie_ca_w
      GeneralEnergie.wa_regie_taux_w += total.totals.wa_regie_taux_w
      GeneralEnergie.wa_terrain_ca_w += total.totals.wa_terrain_ca_w
      GeneralEnergie.wa_terrain_taux_w += total.totals.wa_terrain_taux_w
    })

    const filiale = {
      name: 'GENERALE ENERGIE',
      deposits: [
        {
          name: 'TOTAL',
          totals: GeneralEnergie
        }
      ]
    }
    state.statFiliales.push(filiale)
  },
  SET_REPORTING_STATISTICS_CHECK(state) {
    state.statFiliales = state.statFiliales.filter(f => {
      if (f.checked) {
        f.checked = true
      } else {
        f.checked = false
      }
      return f
    })
  },
  SET_REPORTING_TERRAIN_PROCESSING(state, payload = false) {
    state.reportingTerrainProcessing = payload
  },
  SET_REPORTING_TERRAIN_ERROR(state, payload = null) {
    state.reportingTerrainError = payload
  },
  SET_REPORTING_TERRAIN(state, payload) {
    state.reportingTerrain = payload
  },
  ADD_REPORTING_TERRAIN(state, payload) {
    let colspan = 0
    const deposits = []
    payload.depots.forEach(deposit => {
      const d = {
        id: uuid.v1(),
        name: deposit.depot_name,
        colspan: (deposit.commercials.length || 1) * 5,
        childrens: deposit.commercials.map((commercial, index) => {
          return {
            first: payload.first && index === 0,
            id: uuid.v1(),
            name: commercial.commercial_name,
            weeks: commercial.weeks
          }
        })
      }
      colspan += d.colspan
      deposits.push(d)
    })
    const filiale = {
      id: uuid.v1(),
      name: payload.filial_name,
      colspan: colspan || 1,
      childrens: deposits
    }
    state.reportingTerrain.push(filiale)
  },
  SET_REPORTING_DO_NOTHING() {}
}
const actions = {
  async fetchReportingStats({ commit }, payload) {
    commit('REMOVE_REPORTING_ERROR')
    commit('SET_REPORTING_PROCESSING', true)
    commit('SET_REPORTING_STATS', null)
    try {
      const response = await Axios.post(
        `${domain}/api/get-totals-filiales/weeks/${payload.week_id}`,
        {
          filiale_id: payload.filiale_id,
          depot_id: payload.depot_id
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response && response.data) {
        commit('SET_REPORTING_PROCESSING', false)
        commit('SET_REPORTING_STATS', response.data.data)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_REPORTING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_REPORTING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_REPORTING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchObjectifs({ commit }, payload) {
    commit('REMOVE_REPORTING_ERROR')
    commit('SET_REPORTING_PROCESSING', true)
    commit('INIT_REPORTING_OBJECTIFS')
    try {
      let nextDeposit = this.state.reporting.deposits
        ? this.state.reporting.deposits[0].id
        : null

      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const cancelToken = request.token
      while (nextDeposit && cancelToken == request.token) {
        const response = await Axios.post(
          `${domain}/api/lead-depot-planning`,
          {
            week_id: payload,
            depot_id: nextDeposit
          },
          {
            cancelToken: request.token,
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        if (response && response.data) {
          commit('SET_REPORTING_OBJECTIFS', response.data)
          nextDeposit = response.data.next_depot_id
        }
      }
      commit('SET_REPORTING_OBJECTIFS_TOTAL')
      commit('SET_REPORTING_PROCESSING', false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_REPORTING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_REPORTING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_REPORTING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchVisiblesDeposits({ commit }) {
    commit('REMOVE_REPORTING_ERROR')
    commit('SET_REPORTING_PROCESSING', true)
    try {
      const response = await Axios.get(`${domain}/api/visible-depots`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response && response.data) {
        commit('SET_REPORTING_PROCESSING', false)
        commit('SET_REPORTING_DEPOSITS', response.data)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_REPORTING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_REPORTING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_REPORTING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchStatistics({ commit }, payload) {
    commit('REMOVE_REPORTING_ERROR_STATIC')
    commit('SET_REPORTING_PROCESSING_STATIC', true)
    commit('RESET_REPORTING_STATIC')
    try {
      let nextDeposit = this.state.reporting.deposits
        ? this.state.reporting.deposits[0].id
        : null

      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const cancelToken = request.token
      while (nextDeposit && cancelToken == request.token) {
        const response = await Axios.post(
          `${domain}/api/planning-stat-op`,
          {
            week_id: payload,
            depot_id: nextDeposit
          },
          {
            cancelToken: request.token,
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        if (response && response.data) {
          commit('SET_REPORTING_STATISTICS', response.data)
          nextDeposit = response.data.next_depot_id
        }
      }
      commit('SET_REPORTING_STATISTICS_TOTAL')
      commit('SET_REPORTING_PROCESSING_STATIC', false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_REPORTING_ERROR_STATIC', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_REPORTING_ERROR_STATIC', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_REPORTING_ERROR_STATIC', 'unknown error')
        }
      }
    }
  },
  fetchStatisticsCheck({ commit }) {
    commit('SET_REPORTING_STATISTICS_CHECK')
  },

  async fetchTerrain({ commit }, payload) {
    commit('SET_REPORTING_TERRAIN_PROCESSING', true)
    commit('SET_REPORTING_TERRAIN_ERROR')
    commit('SET_REPORTING_TERRAIN', [])
    try {
      if (payload && Array.isArray(payload)) {
        for (let index = 0; index < payload.length; index++) {
          const id = payload[index]
          const response = await Axios.post(
            domain + `/api/coms-ite-report`,
            { filial_id: id },
            {
              headers: {
                Authorization: `Bearer ${this.state.currentUser.token}`
              }
            }
          )
          commit('ADD_REPORTING_TERRAIN', {
            ...response.data,
            first: index === 0
          })
        }
      }
      commit('SET_REPORTING_TERRAIN_PROCESSING')
    } catch (error) {
      commit('SET_REPORTING_TERRAIN_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_REPORTING_TERRAIN_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_REPORTING_TERRAIN_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
