import Entity from '../Entity'

export default class TemplateSms extends Entity {
  constructor(data) {
    super(data.id)
    this.message = data.message
    this.specific_field = data.specific_field
    this.title = data.title
    this.created_at = data.created_at
    this.created_by = data.created_by
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('TemplateSms::create: "data" is undefined')
    }
    return new TemplateSms(data)
  }
  update(data) {
    this.message = data.message
    this.specific_field = data.specific_field
  }
}
