import Entity from '../Entity'

export default class Objectifs extends Entity {
  constructor(data) {
    super(data.id)
    this.loaded = false
    this.totals = {
      m_objectif: 0,
      pose_objectif: 0,
      m_planife: 0,
      pose_planifie: 0,
      m_realise: 0,
      pose_realise: 0
    }
    this.days = []
    for (let index = 0; index < 7; index++) {
      this.days.push({
        m_objectif: 0,
        pose_objectif: 0,
        m_planife: 0,
        pose_planifie: 0,
        m_realise: 0,
        pose_realise: 0
      })
    }
    this.chartDataDeposit = [['Dépots', 'Poses']]
    this.chartDataFiliale = [['Filiale', 'Poses']]
    this.deposits = []
  }

  static create(data) {
    if (!data) {
      throw new Error('Objectifs::create: "data" is undefined')
    }
    return new Objectifs(data)
  }
}
