import Entity from '../Entity'

export default class ProjectSubType extends Entity {
  constructor(data) {
    super(data.id)

    this.name = data.name
    this.order = data.order
    this.selectedCategorie = []
    this.listSubCategorie = []
    if (data.subcategories) {
      this.subcategories = data.subcategories
      for (let i = 0; i < data.subcategories.data.length; i++) {
        if (
          !this.selectedCategorie.find(
            item => item.id === data.subcategories.data[i].category.data.id
          )
        ) {
          this.selectedCategorie.push(data.subcategories.data[i].category.data)
          for (
            let j = 0;
            j <
            data.subcategories.data[i].category.data.subcategories.data.length;
            j++
          ) {
            this.listSubCategorie.push(
              data.subcategories.data[i].category.data.subcategories.data[j]
            )
          }
        }
      }
    } else {
      this.subcategories = { data: [] }
    }
  }

  /**
   * @param { id, name, sub_categories,ordre } data
   */
  static create(data) {
    if (!data) {
      throw new Error('ProjectSubType::create: "data" is undefined')
    }
    return new ProjectSubType(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
  }
}
