import Axios from '@/axios'
import domain from '@/environment'
let request = null

const state = {
  error: null,
  processing: false,
  devis: [],
  devisCount: 0,
  preteurs: [],
  devisLoading: false
}
const getters = {
  devis: state => state.devis,
  getDevisLoading: state => state.devisLoading,
  devisCount: state => state.devisCount,
  getDevisPreteurs: state => state.preteurs
}
const mutations = {
  SET_ADD_DEVIS(state, payload) {
    state.devis.push(payload)
  },
  SET_DEVIS(state, payload) {
    state.devis = payload.data
    state.devisCount =
      payload.meta && payload.meta.pagination
        ? payload.meta.pagination.total
        : 0
  },
  SET_UPDATE_DEVIS(state, payload) {
    state.devis = state.devis.map(item =>
      item.id == payload.id ? payload : item
    )
  },
  REMOVE_DEVIS(state, id) {
    let key = false
    for (let index = 0; index < state.devis.length; index++) {
      const user = state.devis[index]
      if (user.id == id) {
        key = index
        break
      }
    }
    if (key !== false) {
      state.devis.splice(key, 1)
    }
  },
  DEVIS_LOADING(state, payload) {
    state.devisLoading = payload
  },
  SET_SIGNED(state, payload) {
    state.devis = state.devis.map(item => {
      if (item.id == payload.devis.id) {
        item.signed = payload.devis.signed
        item.signed_bc = payload.devis.signed_bc
      }
      return item
    })
  },
  SET_DEVIS_ITE_PRETEURS(state, payload) {
    state.preteurs = payload
  },
  DO_NOTHING() {}
}
const actions = {
  async addDevis({ commit }, payload) {
    const body = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email_client: payload.email_client,
      email_agent: payload.email_agent,
      phone: payload.phone,
      address: payload.address,
      town: payload.town,
      zipCode: payload.zipCode,
      type_chauffage: payload.type_chauffage,
      zone: payload.zone,
      precariousness: payload.precariousness,
      surface: payload.surface,
      surface_rail: payload.surface_rail ? payload.surface_rail : 0,
      surface_couronnement: payload.surface_couronnement
        ? payload.surface_couronnement
        : 0,
      surface_goutte_eau: payload.surface_goutte_eau
        ? payload.surface_goutte_eau
        : 0,
      surface_angle: payload.surface_angle ? payload.surface_angle : 0,
      surface_rebords: payload.surface_rebords ? payload.surface_rebords : 0
    }
    try {
      const response = await Axios.post(domain + '/api/ite/devis', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ADD_DEVIS', response.data.data)
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: 'Une erreur est survenue'
      }
    }
  },
  async updateDevis({ commit }, payload) {
    const body = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email_client: payload.email_client,
      email_agent: payload.email_agent,
      phone: payload.phone,
      address: payload.address,
      town: payload.town,
      zipCode: payload.zipCode,
      type_chauffage: payload.type_chauffage,
      zone: payload.zone,
      precariousness: payload.precariousness,
      surface: payload.surface,
      surface_rail: payload.surface_rail ? payload.surface_rail : 0,
      surface_couronnement: payload.surface_couronnement
        ? payload.surface_couronnement
        : 0,
      surface_goutte_eau: payload.surface_goutte_eau
        ? payload.surface_goutte_eau
        : 0,
      surface_angle: payload.surface_angle ? payload.surface_angle : 0,
      surface_rebords: payload.surface_rebords ? payload.surface_rebords : 0
    }
    try {
      const response = await Axios.put(
        domain + '/api/ite/devis/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_UPDATE_DEVIS', response.data.data)
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ['Une erreur est survenue']
      }
    }
  },
  async deleteDevis({ commit }, id) {
    try {
      await Axios.delete(domain + '/api/ite/devis/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_DEVIS', id)
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ['Une erreur est survenue']
      }
    }
  },
  async getListDevis({ commit }, payload) {
    commit('DEVIS_LOADING', true)
    try {
      const params = {
        page: payload.page,
        per_page: payload.per_page,
        sort_by: payload.sort_by
      }
      if (payload.full_name_code) {
        params.full_name_code = payload.full_name_code
      }
      if (payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      if (request) {
        request.cancel('Optional message')
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/ite/devis', {
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_DEVIS', response.data)
      commit('DEVIS_LOADING', false)
      return {
        success: true
      }
    } catch (error) {
      commit('DEVIS_LOADING', false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: 'Une erreur est survenue'
      }
    }
  },
  async signDevis({ commit }, payload) {
    const body = {
      type: payload.type,
      paiment: payload.paiment,
      preteur: payload.preteur
    }
    try {
      const response = await Axios.post(
        domain + '/api/ite/devis-sign/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_SIGNED', response.data.data)
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ' '
      }
    }
  },
  async downloadPDF({ commit }, payload) {
    const body = {
      type: payload.type,
      paiment: payload.paiment,
      preteur: payload.preteur
    }
    try {
      const response = await Axios.post(
        domain + '/api/ite/devis-download/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )

      let code = 'file'
      if (payload.type === 'devis') {
        code = payload.code
      } else {
        code = payload.code_bc
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', code + '.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: true,
        error: 'Une erreur est survenue'
      }
    }
  },
  async downloadCC({ commit }, payload) {
    try {
      const response = await Axios.get(
        domain + '/api/ite/generate-cc/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )

      let code = 'ccfile'
      if (payload.code) {
        code = payload.code.replace('DV', 'CC')
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', code + '.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: true,
        error: 'Une erreur est survenue'
      }
    }
  },
  async fetchPreteurs({ commit }) {
    try {
      const response = await Axios.get(domain + '/api/lender', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response.data) {
        commit('SET_DEVIS_ITE_PRETEURS', response.data.data)
      }
      return {
        success: true
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: true,
        error: 'Une erreur est survenue'
      }
    }
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
