import Axios from '@/axios'
import domain from '@/environment'
let request = null

const state = {
  devisAuditError: null,
  devisAuditLoading: false,
  devisAudit: [],
  devisAuditCount: 0
}
const getters = {
  getDevisAuditLoading: state => state.devisAuditLoading,
  getDevisAuditError: state => state.devisAuditError,
  getDevisAudit: state => state.devisAudit,
  getDevisAuditCount: state => state.devisAuditCount
}
const mutations = {
  ADD_DEVIS_AUDIT(state, payload) {
    state.devisAudit.push(payload)
  },
  SET_DEVIS_AUDIT(state, payload) {
    state.devisAudit = payload.data
    state.devisAuditCount =
      payload.meta && payload.meta.pagination
        ? payload.meta.pagination.total
        : 0
  },
  UPDATE_DEVIS_AUDIT(state, payload) {
    state.devisAudit = state.devisAudit.map(item =>
      item.id == payload.id ? payload : item
    )
  },
  REMOVE_DEVIS_AUDIT(state, id) {
    let key = false
    for (let index = 0; index < state.devisAudit.length; index++) {
      const user = state.devisAudit[index]
      if (user.id == id) {
        key = index
        break
      }
    }
    if (key !== false) {
      state.devisAudit.splice(key, 1)
    }
  },
  SET_LOADING_AUDIT(state, payload = false) {
    state.devisAuditLoading = payload
  },
  SET_SIGNED_AUDIT(state, payload) {
    state.devisAudit = state.devisAudit.map(item => {
      if (item.id == payload.devis.id) {
        item.signed = payload.devis.signed
      }
      return item
    })
  },
  DO_NOTHING_AUDIT() {}
}
const actions = {
  async addDevisAudit({ commit }, payload) {
    commit('SET_LOADING_AUDIT', true)
    const body = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email_client: payload.email_client,
      email_agent: payload.email_agent,
      phone: payload.phone,
      address: payload.address,
      town: payload.town,
      zipCode: payload.zipCode,
      revenu: payload.revenu,
      date: payload.date,
      nbr_person: payload.nbr_person
    }
    try {
      const response = await Axios.post(domain + '/api/audit/devis', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_DEVIS_AUDIT', response.data.data)
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: 'Une erreur est survenue'
      }
    }
  },
  async updateDevisAudit({ commit }, payload) {
    commit('SET_LOADING_AUDIT', true)
    const body = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email_client: payload.email_client,
      email_agent: payload.email_agent,
      phone: payload.phone,
      address: payload.address,
      town: payload.town,
      zipCode: payload.zipCode,
      revenu: payload.revenu,
      date: payload.date,
      nbr_person: payload.nbr_person
    }
    try {
      const response = await Axios.put(
        domain + '/api/audit/devis/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_DEVIS_AUDIT', response.data.data)
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ['Une erreur est survenue']
      }
    }
  },
  async deleteDevisAudit({ commit }, id) {
    commit('SET_LOADING_AUDIT', true)
    try {
      await Axios.delete(domain + '/api/audit/devis/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_DEVIS_AUDIT', id)
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ['Une erreur est survenue']
      }
    }
  },
  async fetchDevisAudit({ commit }, payload) {
    commit('SET_LOADING_AUDIT', true)
    try {
      const params = {
        page: payload.page,
        per_page: payload.per_page,
        sort_by: payload.sort_by
      }
      if (payload.full_name_code) {
        params.full_name_code = payload.full_name_code
      }
      if (payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      if (request) {
        request.cancel('Optional message')
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/audit/devis', {
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_DEVIS_AUDIT', response.data)
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: 'Une erreur est survenue'
      }
    }
  },
  async signDevisAudit({ commit }, payload) {
    commit('SET_LOADING_AUDIT', true)
    const body = {
      type: payload.type,
      paiment: payload.paiment,
      preteur: payload.preteur
    }
    try {
      const response = await Axios.post(
        domain + '/api/audit/devis-sign/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_SIGNED_AUDIT', response.data.data)
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: false,
        error: ' '
      }
    }
  },
  async downloadDevisAudit({ commit }, payload) {
    commit('SET_LOADING_AUDIT')
    const body = {
      type: payload.type,
      paiment: payload.paiment,
      preteur: payload.preteur
    }
    try {
      const response = await Axios.post(
        domain + '/api/audit/devis-download/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.code + '.pdf')
      document.body.appendChild(link)
      link.click()
      commit('SET_LOADING_AUDIT')
      return {
        success: true
      }
    } catch (error) {
      commit('SET_LOADING_AUDIT')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          success: false,
          error: error.response.data.error.messages
        }
      }
      return {
        success: true,
        error: 'Une erreur est survenue'
      }
    }
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
